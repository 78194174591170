import hillo from "hillo";
import i18n from "@/i18n";
import dayjs from "dayjs";
// 网页 操作
export const webAddReservation = function (data) {
    return hillo.jsonPost('/reservation/add', {...data, createdBy: 'web:' + location.host})

}

export async function checkTableTimeAvailable(date,
                                              personCount, id) {
    const getTableTime = (await hillo.jsonPost('reservableTable/getTableTime',
        {
            reserveDate: date, peopleCount: personCount,
            userId: id
        }))
    if (getTableTime.code === 200) {
        return getTableTime.data.map(it => {
            it.display = it.startTime + ' / ⌛' + (it.eatingTimeMinute / 60).toFixed(2) + ' ' + i18n.t('Hours')
            return it
        })
    } else {
        return []
    }
}

//获得动态数据
export async function getUserList() {
    return (await hillo.post('user/getList')).data
}

export async function getReservationByToken(token) {
    return (await hillo.post('reservation/info/' + token)).data
}

export async function cancelReservationByToken(token) {
    return (await hillo.post('reservation/cancel/' + token)).data
}

export const ReservationStatus = {
    Confirmed: 'Confirmed',
    Created: 'Created',
    Cancelled: 'Cancelled',
    CheckIn: 'CheckIn',
    NoShow: 'NoShow'
}

export function reservationCanEdit(reservation) {
    return ![ReservationStatus.Cancelled, ReservationStatus.NoShow, ReservationStatus.CheckIn].includes(reservation?.status ?? ReservationStatus.NoShow)
}

/**
 * @param {{deviceId: number}} data
 */
export async function reportToCloud(data) {
    const defaultData = {
        name: '', ip: '', uuid: '', version: '', frontendType: '', deviceId: '',
    };
    const {
        name,
        ip,
        uuid,
        version,
        frontendType,
        deviceId,
    } = Object.assign({}, defaultData, data);
    const frontendLogDTO = {
        name,
        ip,
        uuid,
        version,
        frontendType,
        deviceId,
        timestamp: dayjs().valueOf()
    };
    await hillo.jsonPost("https://cloud-v2.aaden.io/api/frontend-logs/save", frontendLogDTO)
}

